<template>
    <Layout>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-primary fw-medium mb-2">
                                        {{ $t('total betting amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.bet_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="font-size-24 avatar-title fw-bold bg-primary font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-danger fw-medium mb-2">
                                        {{ $t('total payment points') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.win_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title fw-bold bg-danger font-size-24">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-success fw-medium mb-2">
                                        {{ $t('total dividend amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.commission_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-success font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-warning fw-medium mb-2">
                                        {{ $t('total profit') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.profit_bet_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-warning font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-primary fw-medium mb-2">
                                        {{ $t('total slot betting amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.slots_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="font-size-24 avatar-title bg-primary fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-danger fw-medium mb-2">
                                        {{ $t('total slot payment points') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.win_slots_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="font-size-24 avatar-title bg-danger fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-success fw-medium mb-2">
                                        {{ $t('total slot dividend amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.slots_commission_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="font-size-24 avatar-title bg-success font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-warning fw-medium mb-2">
                                        {{ $t('total slot profit') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.profit_slots_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-warning font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-primary fw-medium mb-2">
                                        {{ $t('total casino betting amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.table_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="font-size-24 fw-bold avatar-title bg-primary">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-danger fw-medium mb-2">
                                        {{ $t('total casino payment points') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.win_table_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-danger font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-success fw-medium mb-2">
                                        {{ $t('total casino dividend amount') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.table_commission_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-success font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-warning fw-medium mb-2">
                                        {{ $t('total casino profit') }}
                                    </p>
                                    <h4 class="mb-0 text-end pe-2">
                                        {{
                                            numberStringDecimal(
                                                totals.profit_table_amount
                                            )
                                        }}
                                    </h4>
                                </div>
                                <div
                                    class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                    <span
                                        class="avatar-title bg-warning font-size-24 fw-bold">
                                        원
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-8">
                    <div class="card">
                        <div class="card-header bg-light bg-soft">
                            <h4 class="card-title">
                                {{ $t('betting amount - dividend amount') }}
                            </h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <apexchart
                                    ref="lineChart"
                                    class="apex-charts"
                                    dir="ltr"
                                    height="400"
                                    :series="lineChartData.series"
                                    :options="
                                        lineChartData.chartOptions
                                    "></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header bg-light bg-soft">
                            <h4 class="card-title">
                                {{ $t("today's statistics") }}
                            </h4>
                        </div>
                        <div class="card-body">
                            <div>
                                <apexchart
                                    ref="donutChart"
                                    class="apex-charts"
                                    dir="ltr"
                                    height="373"
                                    :series="donutChartData.series"
                                    :options="
                                        donutChartData.chartOptions
                                    "></apexchart>
                            </div>

                            <div class="text-center text-muted">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="mt-4">
                                            <p class="mb-2 text-truncate">
                                                <i
                                                    class="mdi mdi-circle text-primary me-1"></i>
                                                {{ $t('bet') }}
                                            </p>
                                            <!-- <h5>$ 2,132</h5> -->
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mt-4">
                                            <p class="mb-2 text-truncate">
                                                <i
                                                    class="mdi mdi-circle text-danger me-1"></i>
                                                {{ $t('win amount') }}
                                            </p>
                                            <!-- <h5>$ 1,763</h5> -->
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mt-4">
                                            <p class="mb-2 text-truncate">
                                                <i
                                                    class="mdi mdi-circle text-success me-1"></i>
                                                {{ $t('commission amount') }}
                                            </p>
                                            <!-- <h5>$ 973</h5> -->
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mt-4">
                                            <p class="mb-2 text-truncate">
                                                <i
                                                    class="mdi mdi-circle text-warning me-1"></i>
                                                {{ $t('profit') }}
                                            </p>
                                            <!-- <h5>$ 973</h5> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import { mapActions } from 'vuex';
export default {
    components: {
        Layout,
    },
    mixins: [number, ui],
    data() {
        return {
            donutChartData: {
                series: [],
                chartOptions: {
                    chart: {
                        type: 'donut',
                    },
                    labels: [],
                    colors: ['#556ee6', '#f46a6a', '#34c38f', '#f1b44c'],
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false, // Set to false to hide percentage labels
                    },
                    tooltip: {
                        y: {
                            formatter: this.numberToString,
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '70%',
                            },
                        },
                    },
                },
            },
            lineChartData: {
                series: [],
                chartOptions: {
                    chart: {
                        type: 'line',
                        toolbar: 'false',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 8,
                            opacity: 0.2,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: ['#556ee6', '#f46a6a', '#34c38f', '#f1b44c'],
                    stroke: {
                        curve: 'smooth',
                        width: 3,
                    },
                    xaxis: {
                        categories: [
                            '...',
                            '...',
                            '...',
                            '...',
                            '...',
                            '...',
                            '...',
                        ],
                    },
                    yaxis: {
                        labels: {
                            formatter: this.numberToString,
                        },
                        tickAmount: 4,
                    },
                },
            },
            lineSeries: [],
            totals: {
                bet_amount: 0,
                slots_amount: 0,
                table_amount: 0,
                win_amount: 0,
                win_slots_amount: 0,
                win_table_amount: 0,
                commission_amount: 0,
                slots_commission_amount: 0,
                table_commission_amount: 0,
                profit_bet_amount: 0,
                profit_slots_amount: 0,
                profit_table_amount: 0,
            },
        };
    },
    watch: {
        '$i18n.locale'() {
            // this.$refs.lineChart.updateSeries(
            //     this.formattedSeriesName(this.lineChartData.series)
            // );

            this.lineChartData.series = this.formattedSeriesName(
                this.lineSeries
            );
            this.$refs.lineChart.updateSeries(this.lineChartData.series);

            this.$refs.donutChart.updateOptions({
                labels: this.formattedLabels(
                    this.donutChartData.chartOptions.labels
                ),
            });
        },
    },
    methods: {
        ...mapActions('report', {
            getDashboardSummary: 'dashboardSummary',
        }),
        numberToString(value) {
            return this.numberStringDecimal(value);
        },
        async initDashboard() {
            const res = await this.getDashboardSummary();
            // TOTALS
            this.totals.bet_amount = res.totals.bet_amount;
            this.totals.slots_amount = res.totals.slots_amount;
            this.totals.table_amount = res.totals.table_amount;
            this.totals.win_amount = res.totals.win_amount;
            this.totals.win_slots_amount = res.totals.win_slots_amount;
            this.totals.win_table_amount = res.totals.win_table_amount;
            this.totals.commission_amount = res.totals.commission_amount;
            this.totals.slots_commission_amount =
                res.totals.slots_commission_amount;
            this.totals.table_commission_amount =
                res.totals.table_commission_amount;
            this.totals.profit_bet_amount = res.totals.profit_bet_amount;
            this.totals.profit_slots_amount = res.totals.profit_slots_amount;
            this.totals.profit_table_amount = res.totals.profit_table_amount;

            // LINE
            var minMax = this.findMinMaxForEntireSeries(res.line.series);

            this.lineChartData.chartOptions.xaxis.categories = res.line.labels;
            this.lineChartData.chartOptions.yaxis.labels = {
                formatter: this.numberToString,
            };
            this.lineChartData.chartOptions.yaxis.tickAmount = 4;
            this.lineChartData.chartOptions.yaxis.min = -minMax.globalMax;
            this.lineChartData.chartOptions.yaxis.max = minMax.globalMax;

            this.$refs.lineChart.updateOptions({
                xaxis: {
                    categories: res.line.labels,
                },
                yaxis: {
                    labels: {
                        formatter: this.numberToString,
                    },
                    tickAmount: 4,
                    min: -minMax.globalMax,
                    max: minMax.globalMax,
                },
            });

            this.lineSeries = res.line.series;
            this.lineChartData.series = this.formattedSeriesName(
                res.line.series
            );
            this.$refs.lineChart.updateSeries(this.lineChartData.series);

            //DONUT
            this.donutChartData.chartOptions.labels = res.donut.labels;
            this.$refs.donutChart.updateOptions({
                labels: this.formattedLabels(
                    this.donutChartData.chartOptions.labels
                ),
            });

            this.donutChartData.series = res.donut.series;
            this.$refs.donutChart.updateSeries(this.donutChartData.series);
        },
        formattedSeriesName(series) {
            var vm = this;
            var formattedSeries = [];
            series.forEach((s) => {
                var temp = {};
                temp.name = vm.$t(s.name.toLowerCase());
                temp.data = s.data;
                formattedSeries.push(temp);
            });
            return formattedSeries;
        },
        formattedLabels(labels) {
            var vm = this;
            var temp = [];
            labels.forEach((label) => {
                temp.push(vm.$t(label.toLowerCase()));
            });
            return temp;
        },
        findMinMaxForEntireSeries(series) {
            if (series.length === 0) {
                // Handle the case when the series array is empty
                return 'Series array is empty';
            }

            let globalMax = Number.MIN_SAFE_INTEGER;
            let globalMin = Number.MAX_SAFE_INTEGER;

            series.forEach((dataSet) => {
                const data = dataSet.data;

                if (data.length > 0) {
                    const localMax = Math.max(...data);
                    const localMin = Math.min(...data);

                    globalMax = Math.max(globalMax, localMax);
                    globalMin = Math.min(globalMin, localMin);
                }
            });

            if (
                globalMax === Number.MIN_SAFE_INTEGER ||
                globalMin === Number.MAX_SAFE_INTEGER
            ) {
                // Handle the case when all data arrays are empty
                return 'All data arrays are empty';
            }
            return { globalMax, globalMin };
        },
    },
    mounted() {
        this.initDashboard();
    },
};
</script>
